import { useNavigate } from 'react-router-dom';
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { LogIn, UserPlus } from 'lucide-react';

export default function AuthChoice() {
  const navigate = useNavigate();

  return (
    <div className="flex justify-center items-center min-h-screen w-full bg-gradient-to-b from-gray-50 to-white">
      <div className="w-full max-w-md p-4">
        <h1 className="text-4xl font-bold text-center mb-4 text-gray-900">Networking Pitch Analyzer</h1>
        <p className="text-xl text-gray-600 text-center mb-8">Get started with your pitch analysis journey</p>
        <Card className="border-0 shadow-lg">
          <CardHeader>
            <CardTitle className="text-2xl text-center">Welcome</CardTitle>
          </CardHeader>
          <CardContent className="space-y-4">
            <Button 
              onClick={() => navigate('/login')} 
              className="w-full h-12 text-lg bg-indigo-600 hover:bg-indigo-700"
            >
              <LogIn className="mr-2 h-5 w-5" /> Login
            </Button>
            <Button 
              onClick={() => navigate('/signup')} 
              variant="outline"
              className="w-full h-12 text-lg border-indigo-600 text-indigo-600 hover:bg-indigo-50"
            >
              <UserPlus className="mr-2 h-5 w-5" /> Sign Up
            </Button>
          </CardContent>
        </Card>
      </div>
    </div>
  );
} 