import React, { useState, useEffect } from 'react';
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Progress } from "@/components/ui/progress";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible";
import { ChevronDown } from 'lucide-react';
import { auth, db } from './firebase';
import { collection, query, where, orderBy, getDocs } from 'firebase/firestore';

export default function ResultsPage({ onStartNewPitch }) {
  const [attempts, setAttempts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchResults = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          throw new Error("No user logged in");
        }

        const q = query(
          collection(db, "userAttempts"),
          where("userId", "==", user.uid),
          orderBy("timestamp", "desc")
        );

        const querySnapshot = await getDocs(q);
        const results = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        setAttempts(results);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching results:", err);
        setError("Failed to load results. Please try again.");
        setLoading(false);
      }
    };

    fetchResults();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="max-w-3xl mx-auto p-5">
      <h1 className="text-center text-3xl font-bold mb-8">Networking Pitch Improver Results</h1>
      
      <div className="text-center mb-8">
        <Button onClick={onStartNewPitch}>Start New Pitch</Button>
      </div>
      
      {attempts && attempts.length > 0 ? (
        attempts.map((attempt, index) => (
          <Card key={attempt.id} className="mb-5">
            <CardHeader>
              <CardTitle>Attempt {attempts.length - index} - {attempt.timestamp && new Date(attempt.timestamp.toDate()).toLocaleString()}</CardTitle>
            </CardHeader>
            <CardContent>
              {attempt.analysisData ? (
                <>
                  <div className="mb-4">
                    <h3 className="text-lg font-semibold mb-2">Overall Score: {attempt.analysisData.overallScore || 'N/A'}/100</h3>
                    <Progress value={attempt.analysisData.overallScore || 0} className="h-2 w-full" />
                  </div>
                  <div className="mb-4">
                    <h4 className="text-md font-semibold mb-2">Key Metrics:</h4>
                    {attempt.analysisData.keyMetrics && Object.entries(attempt.analysisData.keyMetrics).map(([key, value]) => (
                      <div key={key} className="flex justify-between items-center mb-2">
                        <span className="capitalize">{key.replace(/([A-Z])/g, ' $1').trim()}</span>
                        <Progress value={value || 0} className="h-2 w-1/2" />
                        <span>{value || 'N/A'}/100</span>
                      </div>
                    ))}
                  </div>
                  <Collapsible>
                    <CollapsibleTrigger className="flex items-center text-primary hover:text-primary-dark">
                      <ChevronDown className="mr-2" />
                      View Detailed Feedback
                    </CollapsibleTrigger>
                    <CollapsibleContent>
                      <div className="mt-4">
                        <h4 className="text-md font-semibold mb-2">Detailed Feedback:</h4>
                        <p>{attempt.analysisData.detailedFeedback}</p>
                      </div>
                      <div className="mt-4">
                        <h4 className="text-md font-semibold mb-2">Your Original Pitch:</h4>
                        <p>{attempt.pitch}</p>
                      </div>
                      <div className="mt-4">
                        <h4 className="text-md font-semibold mb-2">Revised Pitch:</h4>
                        <p>{attempt.analysisData.revisedPitch}</p>
                      </div>
                      <div className="mt-4">
                        <h4 className="text-md font-semibold mb-2">Suggested Golden Geese:</h4>
                        <ul className="list-disc pl-5">
                          {attempt.analysisData.goldenGeeseSuggestions && attempt.analysisData.goldenGeeseSuggestions.map((goose, index) => (
                            <li key={index}>{goose}</li>
                          ))}
                        </ul>
                      </div>
                      <div className="mt-4">
                        <h4 className="text-md font-semibold mb-2">Potential Referral Partners:</h4>
                        <ul className="list-disc pl-5">
                          {attempt.analysisData.referralPartners && attempt.analysisData.referralPartners.map((partner, index) => (
                            <li key={index}>{partner}</li>
                          ))}
                        </ul>
                      </div>
                    </CollapsibleContent>
                  </Collapsible>
                </>
              ) : (
                <p>No analysis data available for this attempt.</p>
              )}
            </CardContent>
          </Card>
        ))
      ) : (
        <p>No attempts found. Start a new pitch to see results here.</p>
      )}

      <div className="text-center mt-10">
        <Button onClick={onStartNewPitch}>Start New Pitch</Button>
      </div>
    </div>
  );
}
