import { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Mic, StopCircle, Send, ChevronRight, ChevronLeft, Loader, RefreshCcw } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import BNIPitchAnalysisDashboard from '../output';
import ResultsPage from '../scores';
import axios from 'axios';
import { Progress } from "@/components/ui/progress";
import ReactMarkdown from 'react-markdown';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible";
import { ChevronDown } from 'lucide-react';
import { auth, db } from '../firebase';
import { collection, addDoc, getDocs, query, where, orderBy } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger, DialogClose } from "@/components/ui/dialog";
import { X } from 'lucide-react';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from "@/components/ui/alert-dialog";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";

export default function Dashboard() {
  const [name, setName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [profession, setProfession] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [pitch, setPitch] = useState('');
  const [analysis, setAnalysis] = useState('');
  const [showResults, setShowResults] = useState(false);
  const [currentAttempt, setCurrentAttempt] = useState(null);
  const [step, setStep] = useState(1);
  const [recognition, setRecognition] = useState(null);
  const [transcript, setTranscript] = useState('');
  const [score, setScore] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isAnalysisExpanded, setIsAnalysisExpanded] = useState(false);
  const [pitchLength, setPitchLength] = useState(45);
  const [analysisData, setAnalysisData] = useState(null);
  const [isMicActive, setIsMicActive] = useState(false);
  const [selectedCarrier, setSelectedCarrier] = useState('');
  const [carriers, setCarriers] = useState([]);

  useEffect(() => {
    // Load user information from localStorage
    const savedInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
    setName(savedInfo.name || '');
    setCompanyName(savedInfo.companyName || '');
    setProfession(savedInfo.profession || '');
    setEmail(savedInfo.email || '');
    setPhone(savedInfo.phone || '');
    // If all fields are filled, start from step 2
    if (savedInfo.name && savedInfo.companyName && savedInfo.profession && savedInfo.email && savedInfo.phone) {
      setStep(2);
    }
  }, []);

  useEffect(() => {
    if ('webkitSpeechRecognition' in window) {
      const recognition = new window.webkitSpeechRecognition();
      recognition.continuous = true;
      recognition.interimResults = true;
      recognition.lang = 'en-US';

      recognition.onresult = (event) => {
        let interimTranscript = '';
        let finalTranscript = '';

        for (let i = 0; i < event.results.length; ++i) {
          if (event.results[i].isFinal) {
            finalTranscript += event.results[i][0].transcript;
          } else {
            interimTranscript += event.results[i][0].transcript;
          }
        }

        setTranscript(prev => {
          const newTranscript = finalTranscript + interimTranscript;
          setPitch(newTranscript);
          return newTranscript;
        });
      };

      setRecognition(recognition);
    } else {
      console.error('Speech recognition not supported');
    }
  }, []);

  useEffect(() => {
    const fetchCarriers = async () => {
      try {
        const response = await axios.get('https://bni-backend-6698076432.us-central1.run.app/api/carriers');
        setCarriers(response.data);
      } catch (error) {
        console.error('Error fetching carriers:', error);
      }
    };
    fetchCarriers();
  }, []);

  const handleStartRecording = () => {
    setIsRecording(true);
    setIsMicActive(true);
    if (recognition) {
      recognition.start();
    }
  };

  const handleStopRecording = () => {
    setIsRecording(false);
    setIsMicActive(false);
    if (recognition) {
      recognition.stop();
    }
    setPitch(transcript);
  };

  const handleTranscriptChange = (e) => {
    setTranscript(e.target.value);
    setPitch(e.target.value);
  };

  const handleAnalyzePitch = async () => {
    if (isRecording) {
      handleStopRecording();
    }
    setIsLoading(true);
    setError(null);
    try {
      console.log('Sending pitch for analysis...');
      const analysisResponse = await axios.post('https://bni-backend-6698076432.us-central1.run.app/api/analyze-pitch', {
        userMessage: pitch,
        pitchLength: pitchLength
      });
      console.log('Analysis received:', analysisResponse.data);
      setAnalysisData(analysisResponse.data);

      await saveResultsToFirestore(analysisResponse.data, pitch);
      nextStep();
    } catch (error) {
      console.error('Error analyzing pitch:', error);
      setError('An error occurred while analyzing your pitch. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const saveResultsToFirestore = async (analysisData, pitch) => {
    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error("No user logged in");
      }
      const docRef = await addDoc(collection(db, "userAttempts"), {
        userId: user.uid,
        timestamp: new Date(),
        pitch: pitch,
        analysisData: analysisData,
        overallScore: analysisData.overallScore
      });
      console.log("Document written with ID: ", docRef.id);
    } catch (e) {
      console.error("Error adding document: ", e);
      throw new Error("Failed to save results. Please try again or check your network settings.");
    }
  };

  const fetchResultsFromFirestore = async () => {
    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error("No user logged in");
      }
      const q = query(
        collection(db, "userAttempts"),
        where("userId", "==", user.uid),
        orderBy("timestamp", "desc")
      );
      const querySnapshot = await getDocs(q);
      const results = [];
      querySnapshot.forEach((doc) => {
        results.push({ id: doc.id, ...doc.data() });
      });
      return results;
    } catch (e) {
      console.error("Error fetching documents: ", e);
      setError("Failed to fetch results. Please try again.");
      return [];
    }
  };

  const handleSendResults = async (method) => {
    const emailContent = `
      <h1>Your Pitch Analysis Results</h1>
      
      <h2>Overall Score: ${analysisData.overallScore}/100</h2>
      
      <h3>Key Metrics:</h3>
      <ul>
        ${Object.entries(analysisData.keyMetrics).map(([key, value]) => `
          <li><strong>${key.replace(/([A-Z])/g, ' $1').trim()}:</strong> ${value}/100</li>
        `).join('')}
      </ul>
      
      <h3>Detailed Feedback:</h3>
      <p>${analysisData.detailedFeedback}</p>
      
      <h3>Revised Pitch:</h3>
      <p><em>${analysisData.revisedPitch}</em></p>
      
      <h3>Golden Goose Suggestions:</h3>
      <ul>
        ${analysisData.goldenGeeseSuggestions.map(suggestion => `<li>${suggestion}</li>`).join('')}
      </ul>
      
      <h3>Potential Referral Partners:</h3>
      <ul>
        ${analysisData.referralPartners.map(partner => `<li>${partner}</li>`).join('')}
      </ul>
    `;

    const textContent = JSON.stringify(analysisData);

    if (method === 'email') {
      try {
        const response = await axios.post('https://bni-backend-6698076432.us-central1.run.app/api/send-email', {
          from: 'Networking Pitch Improver <app@mg.fogal.net>',
          to: email,
          subject: 'Your Pitch Analysis Results',
          html: emailContent
        });
        alert('Results sent via email');
        setShowResults(true);
      } catch (error) {
        console.error('Error sending email:', error);
        setError('Failed to send email. Please try again.');
      }
    } else if (method === 'phone') {
      if (!selectedCarrier) {
        alert('Please select a carrier before sending.');
        return;
      }
      try {
        const response = await axios.post('https://bni-backend-6698076432.us-central1.run.app/api/send-sms', {
          phone,
          carrier: selectedCarrier,
          subject: 'Your Pitch Analysis Results',
          text: textContent,
        });
        alert('Results sent via SMS');
        setShowResults(true);
      } catch (error) {
        console.error('Error sending SMS:', error);
        setError('Failed to send SMS. Please try again.');
      }
    }

    if (analysisData && !currentAttempt) {
      const attempt = {
        date: new Date().toISOString().split('T')[0],
        pitch: pitch,
        analysis: analysisData.detailedFeedback,
        score: analysisData.overallScore,
        analysisData: analysisData
      };
      setCurrentAttempt(attempt);
    }
    setShowResults(true);
  };

  const handleStartNewPitch = () => {
    setStep(2);
    setPitch('');
    setAnalysis('');
    setShowResults(false);
  };

  const nextStep = () => {
    if (step === 1) {
      const userInfo = { name, companyName, profession, email, phone };
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
    }
    setStep(step + 1);
  };

  const prevStep = () => setStep(step - 1);

  const stepVariants = {
    hidden: { opacity: 0, x: 50 },
    visible: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -50 }
  };

  const getShortenedAnalysis = (analysis) => {
    const sentences = analysis.split('. ');
    return sentences.slice(0, 2).join('. ') + (sentences.length > 2 ? '...' : '');
  };

  if (showResults) {
    return <ResultsPage fetchResults={fetchResultsFromFirestore} onStartNewPitch={handleStartNewPitch} />;
  }

  // Rest of your JSX remains the same...
  return (
    <div className="flex justify-center items-center min-h-screen w-full">
      <div className="w-full max-w-2xl p-4">
        <div className="flex flex-col items-center">
          {/* {<h1 className="text-3xl font-bold text-center mb-8">Networking Pitch Improver</h1>} */}

          {/* Step Indicators */}
          <div className="flex w-full mb-8">
            {[1, 2, 3, 4].map((i) => (
              <div
                key={i}
                className={`w-1/4 h-2 rounded-full ${
                  i <= step ? 'bg-indigo-600' : 'bg-gray-200'
                }`}
              />
            ))}
          </div>

          {/* AnimatePresence and motion.div */}
          <AnimatePresence mode="wait">
            <motion.div
              key={step}
              variants={stepVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              transition={{ duration: 0.3 }}
              className="w-full"
            >
              {/* Step 1 */}
              {step === 1 && (
                <Card className="border-0 shadow-lg">
                  <CardHeader>
                    <CardTitle className="text-2xl text-center text-gray-900">Networking Pitch Analyzer</CardTitle>
                    <p className="text-gray-600 text-center mt-2">
                      Let's start by getting to know you better. This information helps us personalize your pitch analysis 
                      and ensures your results can be easily shared with you.
                    </p>
                  </CardHeader>
                  <CardContent>
                    <div className="grid gap-6">
                      <div className="grid grid-cols-2 gap-4">
                        <div>
                          <Label htmlFor="name" className="text-gray-700">Name</Label>
                          <Input 
                            id="name" 
                            value={name} 
                            onChange={(e) => setName(e.target.value)} 
                            className="border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                            placeholder="Your full name"
                          />
                        </div>
                        <div>
                          <Label htmlFor="companyName" className="text-gray-700">Company Name</Label>
                          <Input 
                            id="companyName" 
                            value={companyName} 
                            onChange={(e) => setCompanyName(e.target.value)} 
                            className="border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                            placeholder="Your company"
                          />
                        </div>
                      </div>
                      <div>
                        <Label htmlFor="profession" className="text-gray-700">Profession</Label>
                        <Input 
                          id="profession" 
                          value={profession} 
                          onChange={(e) => setProfession(e.target.value)} 
                          className="border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                          placeholder="Your profession or job title"
                        />
                      </div>
                      <div className="grid grid-cols-2 gap-4">
                        <div>
                          <Label htmlFor="email" className="text-gray-700">Email</Label>
                          <Input 
                            id="email" 
                            type="email" 
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)} 
                            className="border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                            placeholder="your@email.com"
                          />
                        </div>
                        <div>
                          <Label htmlFor="phone" className="text-gray-700">Phone Number</Label>
                          <Input 
                            id="phone" 
                            type="tel" 
                            value={phone} 
                            onChange={(e) => setPhone(e.target.value)} 
                            className="border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                            placeholder="Your phone number"
                          />
                        </div>
                      </div>
                      <Button 
                        onClick={nextStep} 
                        className="mt-6 bg-indigo-600 hover:bg-indigo-700 text-white font-semibold py-2 px-4 rounded-lg transition-colors w-full flex items-center justify-center"
                      >
                        Continue to Recording <ChevronRight className="ml-2 h-5 w-5" />
                      </Button>
                    </div>
                  </CardContent>
                </Card>
              )}

              {/* Step 2 */}
              {step === 2 && (
                <Card className="border-0 shadow-lg">
                  <CardHeader>
                    <CardTitle className="text-2xl text-center text-gray-900">Record Your Pitch</CardTitle>
                    <p className="text-gray-600 text-center mt-2">
                      Let's record your networking pitch. We'll guide you through each step to ensure the best results.
                    </p>
                  </CardHeader>
                  <CardContent className="space-y-6">
                    {/* Step 2.1: Set Pitch Length */}
                    <div className="space-y-4">
                      <Label htmlFor="pitchLength" className="text-gray-700 text-lg flex items-center gap-2">
                        <span className="bg-indigo-600 text-white w-6 h-6 rounded-full flex items-center justify-center text-sm">1</span>
                        Set your pitch length
                      </Label>
                      <div className="pl-8 flex items-center gap-2">
                        <div className="w-24">
                          <Input
                            id="pitchLength"
                            type="number"
                            value={pitchLength}
                            onChange={(e) => setPitchLength(parseInt(e.target.value))}
                            min="1"
                            className="border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 text-lg"
                            placeholder="45"
                          />
                        </div>
                        <span className="text-gray-500">seconds</span>
                        <p className="text-sm text-gray-500 ml-2">
                          (recommended: 45 seconds)
                        </p>
                      </div>
                    </div>

                    {/* Step 2.2: Recording Controls */}
                    <div className="space-y-4">
                      <Label className="text-gray-700 text-lg flex items-center gap-2">
                        <span className="bg-indigo-600 text-white w-6 h-6 rounded-full flex items-center justify-center text-sm">2</span>
                        Record your pitch
                      </Label>
                      <div className="pl-8 space-y-4">
                        <div className="flex justify-center gap-4">
                          <Button 
                            onClick={handleStartRecording} 
                            disabled={isRecording}
                            className={`${
                              isMicActive 
                                ? "bg-red-500 hover:bg-red-600" 
                                : "bg-indigo-600 hover:bg-indigo-700"
                            } text-white px-6 py-3 rounded-lg transition-all duration-200 transform hover:scale-105`}
                          >
                            <Mic className="mr-2 h-5 w-5" /> 
                            {isMicActive ? "Recording..." : "Start Recording"}
                          </Button>
                          <Button 
                            onClick={handleStopRecording} 
                            disabled={!isRecording}
                            className="bg-gray-200 hover:bg-gray-300 text-gray-700 px-6 py-3 rounded-lg transition-all duration-200 transform hover:scale-105 disabled:opacity-50"
                          >
                            <StopCircle className="mr-2 h-5 w-5" /> Stop Recording
                          </Button>
                        </div>
                        {isMicActive && (
                          <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -20 }}
                            transition={{ duration: 0.3 }}
                          >
                            <Alert className="bg-red-50 border-red-200">
                              <AlertTitle className="text-red-800 flex items-center gap-2">
                                <span className="animate-pulse">●</span> Recording in Progress
                              </AlertTitle>
                              <AlertDescription className="text-red-600">
                                Speak clearly into your microphone. Your pitch will appear in the text box below.
                              </AlertDescription>
                            </Alert>
                          </motion.div>
                        )}
                      </div>
                    </div>

                    {/* Step 2.3: Review and Edit */}
                    <div className="space-y-4">
                      <Label className="text-gray-700 text-lg flex items-center gap-2">
                        <span className="bg-indigo-600 text-white w-6 h-6 rounded-full flex items-center justify-center text-sm">3</span>
                        Review and edit your pitch
                      </Label>
                      <div className="pl-8">
                        <div className="flex justify-between items-center mb-2">
                          <p className="text-sm text-gray-500">
                            Feel free to edit the text to correct any transcription errors
                          </p>
                          <Button 
                            onClick={() => {
                              setTranscript('');
                              setPitch('');
                            }}
                            variant="outline"
                            className="text-red-600 hover:text-red-700 hover:bg-red-50"
                          >
                            <RefreshCcw className="mr-2 h-4 w-4" /> Reset Transcript
                          </Button>
                        </div>
                        <Textarea
                          placeholder="Your transcribed pitch will appear here..."
                          value={transcript}
                          onChange={handleTranscriptChange}
                          rows={6}
                          className="border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 text-lg"
                        />
                      </div>
                    </div>

                    {/* Navigation Buttons */}
                    <div className="flex justify-between pt-6">
                      <Button 
                        onClick={prevStep} 
                        variant="outline"
                        className="border-gray-300 hover:bg-gray-50 text-gray-700"
                      >
                        <ChevronLeft className="mr-2 h-4 w-4" /> Back
                      </Button>
                      <Button
                        onClick={handleAnalyzePitch}
                        disabled={!pitch || isLoading}
                        className="bg-indigo-600 hover:bg-indigo-700 text-white transition-all duration-200 transform hover:scale-105"
                      >
                        {isLoading ? (
                          <>
                            <Loader className="mr-2 h-4 w-4 animate-spin" /> Analyzing...
                          </>
                        ) : (
                          <>
                            Analyze Pitch <ChevronRight className="ml-2 h-4 w-4" />
                          </>
                        )}
                      </Button>
                    </div>

                    {/* Error Display */}
                    {error && (
                      <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.3 }}
                      >
                        <Alert variant="destructive">
                          <AlertTitle>Error</AlertTitle>
                          <AlertDescription>{error}</AlertDescription>
                        </Alert>
                      </motion.div>
                    )}

                    {/* Help Dialog */}
                    <Dialog>
                      <DialogTrigger asChild>
                        <Button 
                          variant="outline" 
                          className="absolute top-4 right-4 rounded-full w-8 h-8 p-0 border-gray-300 hover:bg-gray-50"
                        >
                          ?
                        </Button>
                      </DialogTrigger>
                      <DialogContent className="sm:max-w-md bg-white">
                        <div className="flex flex-col space-y-6">
                          <div className="flex justify-between items-center">
                            <DialogTitle className="text-xl font-semibold">How to Record Your Pitch</DialogTitle>
                            <DialogClose className="rounded-full p-1.5 hover:bg-gray-100">
                              <X className="h-4 w-4 text-gray-500" />
                            </DialogClose>
                          </div>

                          <div className="space-y-6">
                            <div className="flex items-start gap-4">
                              <div className="bg-indigo-50 w-8 h-8 rounded-full flex items-center justify-center flex-shrink-0">
                                <span className="text-indigo-600">1</span>
                              </div>
                              <div>
                                <h4 className="font-semibold text-gray-900">Set Your Pitch Length</h4>
                                <p className="text-gray-600">Choose how long your pitch should be. We recommend 45 seconds.</p>
                              </div>
                            </div>

                            <div className="flex items-start gap-4">
                              <div className="bg-indigo-50 w-8 h-8 rounded-full flex items-center justify-center flex-shrink-0">
                                <span className="text-indigo-600">2</span>
                              </div>
                              <div>
                                <h4 className="font-semibold text-gray-900">Record Your Pitch</h4>
                                <p className="text-gray-600">Click "Start Recording" and begin speaking. Click "Stop" when finished.</p>
                              </div>
                            </div>

                            <div className="flex items-start gap-4">
                              <div className="bg-indigo-50 w-8 h-8 rounded-full flex items-center justify-center flex-shrink-0">
                                <span className="text-indigo-600">3</span>
                              </div>
                              <div>
                                <h4 className="font-semibold text-gray-900">Review and Edit</h4>
                                <p className="text-gray-600">Check the transcribed text and make any necessary corrections.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </DialogContent>
                    </Dialog>
                  </CardContent>
                </Card>
              )}

              {/* Step 3 */}
              {step === 3 && (
                <BNIPitchAnalysisDashboard 
                  analysisData={analysisData} 
                  onNextStep={nextStep}
                  onPrevStep={prevStep}
                  userPitch={pitch}
                />
              )}

              {/* Step 4 */}
              {step === 4 && (
                <Card>
                  <CardHeader>
                    <CardTitle>Your Pitch Results Summary</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <div className="mb-4">
                      <h3 className="text-lg font-semibold mb-2">Overall Score: {analysisData.overallScore}/100</h3>
                      <Progress value={analysisData.overallScore} className="h-2 w-full" />
                    </div>
                    <div className="mb-4">
                      <h4 className="text-md font-semibold mb-2">Key Metrics:</h4>
                      <ul>
                        {Object.entries(analysisData.keyMetrics).map(([key, value]) => (
                          <li key={key} className="flex justify-between">
                            <span className="capitalize">{key}</span>
                            <span>{value}/100</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="mb-4">
                      <h4 className="text-md font-semibold mb-2">Summary Feedback:</h4>
                      <p>{getShortenedAnalysis(analysisData.detailedFeedback)}</p>
                    </div>
                    <Tabs defaultValue="email">
                      <TabsList className="grid w-full grid-cols-2 mb-4">
                        <TabsTrigger value="email">Email</TabsTrigger>
                        <TabsTrigger value="phone">Phone</TabsTrigger>
                      </TabsList>
                      <TabsContent value="email">
                        <div className="flex items-center space-x-2 mb-4">
                          <Input type="email" placeholder="Confirm your email" value={email} onChange={(e) => setEmail(e.target.value)} />
                          <Button onClick={() => handleSendResults('email')}>
                            <Send className="mr-2 h-4 w-4" /> Send
                          </Button>
                        </div>
                      </TabsContent>
                      <TabsContent value="phone">
                        <div className="flex flex-col space-y-2 mb-4">
                          <Input type="tel" placeholder="Confirm your phone number" value={phone} onChange={(e) => setPhone(e.target.value)} />
                          <Select value={selectedCarrier} onValueChange={setSelectedCarrier}>
                            <SelectTrigger>
                              <SelectValue placeholder="Select your carrier" />
                            </SelectTrigger>
                            <SelectContent>
                              {carriers.map((carrier) => (
                                <SelectItem key={carrier.id} value={carrier.id}>{carrier.name}</SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                          <Button onClick={() => handleSendResults('phone')} disabled={!selectedCarrier}>
                            <Send className="mr-2 h-4 w-4" /> Send
                          </Button>
                        </div>
                      </TabsContent>
                    </Tabs>
                    <Button onClick={prevStep} variant="outline" className="w-full mt-4">
                      <ChevronLeft className="mr-2 h-4 w-4" /> Back to Analysis
                    </Button>
                  </CardContent>
                </Card>
              )}
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
} 