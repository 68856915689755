import React from 'react';
import { Mic, Clock, Target, AlertCircle, PenTool, Users, ChevronRight, Play, Award, Zap } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

function FeatureCard({ icon: Icon, title, description }) {
  return (
    <div className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-shadow">
      <div className="h-12 w-12 bg-indigo-50 rounded-lg flex items-center justify-center mb-4">
        <Icon className="h-6 w-6 text-indigo-600" />
      </div>
      <h3 className="text-lg font-semibold mb-2 text-gray-900">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  );
}

export default function LandingPage() {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      {/* Hero Section */}
      <div className="relative overflow-hidden">
        <div className="absolute inset-0 bg-indigo-50 opacity-50 z-0" />
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-20 pb-16 relative z-10">
          <div className="text-center max-w-3xl mx-auto">
            <h1 className="text-5xl font-bold text-gray-900 mb-6">
              Perfect Your Pitch with AI-Powered Analysis
            </h1>
            <p className="text-xl text-gray-600 mb-8">
              Transform your networking pitch into a powerful tool for success. Get real-time feedback, identify golden opportunities, and connect with the right partners.
            </p>
            <div className="flex gap-4 justify-center">
              <button 
                onClick={() => navigate('/auth')} 
                className="bg-indigo-600 text-white px-8 py-3 rounded-lg font-semibold flex items-center gap-2 hover:bg-indigo-700 transition-colors"
              >
                Start Analyzing <Play className="h-5 w-5" />
              </button>
              <button className="border border-gray-300 bg-white px-8 py-3 rounded-lg font-semibold flex items-center gap-2 hover:bg-gray-50 transition-colors">
                Learn More <ChevronRight className="h-5 w-5" />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Stats Section */}
      <div className="bg-white py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-center">
            <div className="p-6">
              <div className="text-4xl font-bold text-indigo-600 mb-2">93%</div>
              <p className="text-gray-600">Improvement in pitch effectiveness</p>
            </div>
            <div className="p-6">
              <div className="text-4xl font-bold text-indigo-600 mb-2">2.5x</div>
              <p className="text-gray-600">More referrals generated</p>
            </div>
            <div className="p-6">
              <div className="text-4xl font-bold text-indigo-600 mb-2">5000+</div>
              <p className="text-gray-600">Professionals using our platform</p>
            </div>
          </div>
        </div>
      </div>

      {/* Features Grid */}
      <div className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">Powerful Features for Perfect Pitches</h2>
            <p className="text-xl text-gray-600">Everything you need to deliver compelling elevator pitches that convert</p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <FeatureCard
              icon={Clock}
              title="Real-time Analysis"
              description="Get instant feedback on timing, pace, and delivery while you practice your pitch."
            />
            <FeatureCard
              icon={Target}
              title="Golden Goose Detection"
              description="Identify your most valuable opportunities and perfect your pitch for maximum impact."
            />
            <FeatureCard
              icon={AlertCircle}
              title="Kill Phrase Detection"
              description="Avoid common pitfalls and phrases that could weaken your presentation."
            />
            <FeatureCard
              icon={Zap}
              title="Smart Feedback"
              description="Receive actionable suggestions to improve your pitch structure and content."
            />
            <FeatureCard
              icon={PenTool}
              title="Pitch Refinement"
              description="AI-powered assistance to help you craft the perfect elevator pitch."
            />
            <FeatureCard
              icon={Users}
              title="Partner Matching"
              description="Connect with ideal referral partners based on your pitch analysis."
            />
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-indigo-600 text-white py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <div className="inline-block p-2 bg-indigo-500 rounded-full mb-8">
            <Award className="h-8 w-8" />
          </div>
          <h2 className="text-3xl font-bold mb-4">Ready to Transform Your Networking Game?</h2>
          <p className="text-xl text-indigo-100 mb-8 max-w-2xl mx-auto">
            Join thousands of professionals who have already improved their networking success with our AI-powered platform.
          </p>
          <button className="bg-white text-indigo-600 px-8 py-3 rounded-lg font-semibold hover:bg-indigo-50 transition-colors">
            Start Free Trial
          </button>
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-gray-900 text-gray-300 py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-center gap-2 mb-8">
            <Mic className="h-6 w-6" />
            <span className="text-xl font-semibold">Networking Pitch Analyzer</span>
          </div>
          <div className="text-center text-sm">
            <p>© 2024 Networking Pitch Analyzer. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
} 