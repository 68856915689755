import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAttKFaS28BNDxJtWAAFV5zdaN3BygD_TQ",
  authDomain: "bni-pitch.firebaseapp.com",
  projectId: "bni-pitch",
  storageBucket: "bni-pitch.appspot.com",
  messagingSenderId: "446801730372",
  appId: "1:446801730372:web:550b442757ee879ffd5511",
  measurementId: "G-D4PRPQX9ZP"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);

export { app, analytics, auth, db };