import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { auth } from './firebase';
import LandingPage from './pages/LandingPage';
import AuthChoice from './pages/AuthChoice';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Dashboard from './components/Dashboard';

export default function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsLoggedIn(!!user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route 
          path="/auth" 
          element={isLoggedIn ? <Navigate to="/dashboard" /> : <AuthChoice />} 
        />
        <Route 
          path="/login" 
          element={
            isLoggedIn ? 
              <Navigate to="/dashboard" /> : 
              <Login onLoginSuccess={() => setIsLoggedIn(true)} />
          } 
        />
        <Route 
          path="/signup" 
          element={
            isLoggedIn ? 
              <Navigate to="/dashboard" /> : 
              <Signup onLoginSuccess={() => setIsLoggedIn(true)} />
          } 
        />
        <Route 
          path="/dashboard/*" 
          element={
            isLoggedIn ? 
              <Dashboard /> : 
              <Navigate to="/login" />
          } 
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}